import { useEffect, useState } from "react";

export const arrayHasItems = (array) => {
    return array && array instanceof Array && array.length > 0;
}

export function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
        () => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay],
    );
    return debouncedValue;
}

export function useBackToTop(isLoading) {
    
    useEffect(() => {
        if (typeof window !== "undefined" && !isLoading) {
            const scrollButton = document.getElementById('scroll-to-top-button');
            const main = document.getElementById('main');
            if (main && scrollButton) {
                const handleScroll = () => {
                    let firstItem = document.querySelector('.data-item-card');
                    const clientCardHeight = firstItem?.clientHeight || 0
                    const cardOffsetTop = firstItem?.offsetTop || 0;
                    const scrollTop = main.scrollTop;

                    if (firstItem && scrollTop >= clientCardHeight + cardOffsetTop) {
                        scrollButton.classList.add('block');
                        scrollButton.classList.remove('hidden');
                    } else {
                        scrollButton.classList.add('hidden');
                        scrollButton.classList.remove('block');
                    }
                };
                main.addEventListener('scroll', handleScroll);
                scrollButton.addEventListener('click', () => {
                    main.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                });
                return () => {
                    main.removeEventListener('scroll', handleScroll);
                    scrollButton.removeEventListener('click', handleScroll);
                };
            }
        }
    }, [isLoading]);
}

export const arrayMove = (array, from, to) => {
    const slicedArray = array.slice()
    slicedArray.splice(to < 0 ? array.length + to : to, 0, slicedArray.splice(from, 1)[0])
    return slicedArray
}

export const getFilteredCoursesByRole = (
    courses,
    role,
) => {
    return courses.filter((course) => hasAccessToCourse(course, role));
}

export const hasAccessToCourse = (course, role) => {
    if (role === 'admin') return true
    if ((role === 'coach' || role === 'premium') && course.visibleToPremium) return true
    if (role === 'basic' && course.visibleToBasic) return true
    return false
}

export const removeLoadingKey = (loadingKey, keys) => {
    if (!arrayHasItems(keys)) return [];
    const currentKeys = [...keys].filter((key) => key !== loadingKey);
    return currentKeys;
}

export const getDetailInformationMessage = (
    itemInformation,
    type = "default"
) => {
    let message = ""
    if (!itemInformation) return message
    const tags = itemInformation?.Tags;
    const description = itemInformation?.description;
    if (arrayHasItems(tags)) {
        message = message + `Tags: ${tags.map((tag) => tag.name).join(", ")}`
    }
    if (type === "course-title" && description) {
        const marginDescription = arrayHasItems(tags) ? "mt-1" : "";
        message = message + `<div class="${marginDescription}">Description: ${description}</div>`
    }
    return message
}

export const formatNumber = (value) => {
    if (!value) return null
    const newValue = Number(value).toFixed(2)
    return newValue
}

export const userIsCreatedAfterSelectedDate = (userCreatedDate, selectedDate) => {
    if (!userCreatedDate || !selectedDate) return false;
    try {
        const createdDate = userCreatedDate.split("T")[0];
        return createdDate > selectedDate;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export const normalizeString = (str) => {
    try {
        return str
            .normalize("NFKD")
            .replace(/[\p{Emoji_Presentation}\p{Emoji}\p{Extended_Pictographic}]/gu, "")
            .replace(/[^a-zA-Z0-9\s]/g, "")
            .trim()
            .toLowerCase();
    } catch (error) {
        console.error(error);
        return str;
    }
}

export const getListByAlphabet = (array, key) => {
    if (!array) return []
    return array.filter((item) => !item[key].startsWith("DELETED -"))
        .sort((a, b) => {
            const firstTitle = normalizeString(a?.[key] || "");
            const secondTitle = normalizeString(b?.[key] || "");
            if (firstTitle < secondTitle) return -1;
            if (firstTitle > secondTitle) return 1;
            return 0;
        })
}